import React, { Fragment } from 'react';
import { Header, ReportData } from '../components';

const Home = () => (
  <Fragment>
    <Header />
    <ReportData />
  </Fragment>
);

export default Home;

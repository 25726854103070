import { toast } from 'react-toastify';

const toastConfigObj = {
  position: 'bottom-right',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const toastSuccess = (toastMessage) => {
  toast.success(toastMessage, toastConfigObj);
};

export const toastError = (toastMessage) => {
  toast.error(toastMessage, toastConfigObj);
};

export const getToastErrorMessage = (error) => {
  let toastMessage = 'Something went wrong! Please try again!';
  toastMessage += JSON.stringify(error?.response?.data) || '';
  toastMessage += JSON.stringify(error?.response?.message) || '';
  toastMessage += error.message ? `. ${error.message}` : '';
  return toastMessage;
};

export const getHumanReadableTime = (secondsAsNumber) => {
  // this fn will transform time from seconds into human readable format

  const hours = Math.floor(secondsAsNumber / 3600);
  const minutes = Math.floor(
    (((secondsAsNumber % 31536000) % 86400) % 3600) / 60
  );
  const seconds = Math.round(
    (((secondsAsNumber % 31536000) % 86400) % 3600) % 60
  );

  let result = '';

  if (hours) {
    if (hours > 1) {
      result += ` ${hours} ore,`;
    } else {
      result += ` ${hours} ora,`;
    }
  }

  if (minutes || hours) {
    result += ` ${minutes} min,`;
  }

  result += ` ${seconds} sec.`;

  return result.trim();
};

const getCSVForOneStats = (headers, stats, start_ts, end_ts) => {
  const generalRow = [];
  const betweenStationsRows = [];
  const perVehicleRow = [];

  headers.forEach((headerKey, index) => {
    if (index === 0) return generalRow.push('general');
    if (index === 1) return generalRow.push(start_ts);
    if (index === 2) return generalRow.push(end_ts);
    if (index === 11) return generalRow.push('-');

    generalRow.push(stats[headerKey]);
  });

  stats.stats_per_stations.forEach((data) => {
    const stationsRow = [];
    headers.forEach((headerKey, index) => {
      if (index === 0) return stationsRow.push('between_stations');
      if (index === 1) return stationsRow.push(start_ts);
      if (index === 2) return stationsRow.push(end_ts);
      if (index >= 8 && index <= 11) return stationsRow.push(data[headerKey]);
      if (index === 12) return stationsRow.push('-');

      stationsRow.push(data.stats[headerKey]);
    });

    betweenStationsRows.push(stationsRow);
  });

  stats.stats_per_vehicle.forEach((data) => {
    const vehicleRow = [];
    headers.forEach((headerKey, index) => {
      if (index === 0) return vehicleRow.push('per_vehicle');
      if (index === 1) return vehicleRow.push(start_ts);
      if (index === 2) return vehicleRow.push(end_ts);
      if (index >= 8 && index <= 11) return vehicleRow.push(stats[headerKey]);
      if (index === 12 || index === 13) return vehicleRow.push(data[headerKey]);

      vehicleRow.push(data.stats[headerKey]);
    });

    perVehicleRow.push(vehicleRow);
  });

  return [generalRow, ...betweenStationsRows, ...perVehicleRow];
};

export const getCSVFileContent = (stats, start_ts, end_ts, groupedStats) => {
  const headers = [
    'stat_type',
    'start_ts',
    'end_ts',
    'min_time',
    'avg_time',
    'avg_speed',
    'max_time',
    'stddev',
    'first_station_id',
    'first_station_name',
    'second_station_name',
    'second_station_id',
    'vehicle_id',
    'vehicle_name',
  ];

  const statsResult = getCSVForOneStats(headers, stats, start_ts, end_ts);
  const result = [headers, ...statsResult];

  groupedStats?.forEach((data) => {
    const groupedStatsResult = getCSVForOneStats(
      headers,
      data.stats,
      data.start_ts,
      data.end_ts
    );
    result.push(...groupedStatsResult);
  });

  return result;
};

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import GlobalContext from '../GlobalContext';
import { authenticateUser } from '../actions';
import { globalState as initialGlobalState } from '../GlobalContext';

const Page = styled('div')`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    border-bottom: 2px solid ${theme.palette.primary.main};
    background-color: '#FFFFFF';
  `
);

const TranzyLogo = styled('img')({
  cursor: 'pointer',
});

const TranzyTitle = styled('h3')(
  ({ theme }) => `
  fontFamily: 'Montserrat, Sans-serif';
  color: ${theme.palette.primary.main};
  `
);

const TranzyLogin = styled('h2')(
  ({ theme }) => `
  fontFamily: 'Montserrat, Sans-serif';
  color: ${theme.palette.primary.main};
  `
);

const Form = styled('form')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
`;

const Login = () => {
  const {
    globalState: {
      userData: { isAuthenticated },
    },
    setGlobalState,
  } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setGlobalState(initialGlobalState);
  }, [setGlobalState]);

  useEffect(() => {
    if (isAuthenticated) {
      return navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const onSubmitClick = (event) => {
    event.preventDefault();
    if (email && password) {
      authenticateUser({ email, password }, setGlobalState, navigate);
    }
  };

  return (
    <Page>
      <HeaderWrapper px={4} py={1}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <TranzyLogo
            height={45}
            src={'https://tranzy.wink.ro/uploads/info_header.png'}
            alt='tranzy-info-header'
            onClick={() => window.location.reload()}
          />

          <TranzyTitle>Tranzy Route Reports</TranzyTitle>
        </Grid>
      </HeaderWrapper>

      <Form noValidate autoComplete='off' onSubmit={onSubmitClick}>
        <TranzyLogin>Login</TranzyLogin>

        <Grid container justify='center' direction='column' spacing={3}>
          <Grid item>
            <TextField
              fullWidth
              id='email'
              label='Email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              id='password'
              label='Password'
              type='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>

          <Grid item>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              type='submit'
              className={''}
              disabled={!email || !password}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Page>
  );
};
export default Login;

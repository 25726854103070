import React, { Fragment, useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from '@mui/material';
import {
  ArrowCircleUp as ArrowCircleUpIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  ArrowCircleDown as ArrowCircleDownIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import moment from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/lab';

import GlobalContext from '../GlobalContext';

import { fetchRoutes, fetchReport } from '../actions';
import {
  WEEK_DAYS,
  DATE_FORMAT,
  TIME_FORMAT,
  LOCAL_STORAGE_AUTH_TOKEN,
} from '../utils/constants';

const TranzyLogo = styled('img')({
  cursor: 'pointer',
});
const TranzyTitle = styled('h3')(
  ({ theme }) => `
  fontFamily: 'Montserrat, Sans-serif';
  color: ${theme.palette.primary.main};
  `
);

const initialFormDataState = {
  route: null,
  rsWay: 'tour',
  startStation: null,
  endStation: null,
  startDate: moment().subtract(2, 'days').startOf('day'),
  endDate: moment().subtract(1, 'days').startOf('day'),
  startHour: moment().set({ hour: 5, minute: 0, second: 0 }),
  endHour: moment().set({ hour: 9, minute: 0, second: 0 }),
  groupStats: false,
  byDay: true,
  byHourInterval: false,
  hourlyDiscrete: false,
  filterPeriodDay: false,
  filterGroupedPeriodDay: false,
  periodDays: [],
  removeOutliers: false,
};

const Header = () => {
  const {
    globalState: {
      cityConfig: { cityColor, cityLabel, database, logoImage, theme },
    },
    setGlobalState,
  } = useContext(GlobalContext);

  const [routes, setRoutes] = useState([]);
  const [formData, setFormData] = useState(initialFormDataState);
  const [closedHeader, setClosedHeader] = useState(false);

  useEffect(() => {
    (async () => {
      const routes = await fetchRoutes(database);
      setFormData(initialFormDataState);
      setClosedHeader(false);
      setRoutes(routes);
    })();
  }, [database]);

  const onFormDataChange = (key, value) => {
    setFormData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onGenerateReportClick = async () => {
    await fetchReport(database, formData, setGlobalState);
    setClosedHeader(true);
  };

  const checkFormDateValidity = () => {
    const { startDate, endDate, startHour, endHour, byHourInterval } = formData;

    if (
      !startDate?._isValid ||
      !endDate?._isValid ||
      moment(startDate).isAfter(moment(endDate), 'day')
    ) {
      return false;
    }

    if (
      byHourInterval &&
      (!startHour?._isValid ||
        !endHour?._isValid ||
        moment(startHour).isSameOrAfter(moment(endHour), 'minute'))
    ) {
      return false;
    }

    return true;
  };

  const disableSubmitButtton = () => {
    const {
      route,
      startStation,
      endStation,
      groupStats,
      byDay,
      byHourInterval,
      hourlyDiscrete,
      filterPeriodDay,
      periodDays,
    } = formData;

    const formDatesAreValid = checkFormDateValidity();

    if (route && startStation && endStation && formDatesAreValid) {
      if (groupStats) {
        if (!byDay && !byHourInterval && !hourlyDiscrete) {
          return true;
        }

        if (filterPeriodDay && !periodDays.length) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  const onLogoutClick = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
    setGlobalState((state) => ({
      ...state,
      userData: {
        isAuthenticated: false,
        email: '',
      },
    }));
  };

  const renderLogoTitleRow = () => (
    <Box
      px={4}
      py={1}
      sx={{
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#FFFFFF',
      }}
    >
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <TranzyLogo
            height={45}
            src={logoImage}
            alt='tranzy-info-header'
            onClick={() => window.location.reload()}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            alignItems='center'
            justifyContent='flex-end'
            spacing={3}
          >
            <Grid item>
              <TranzyTitle>Tranzy Route Reports</TranzyTitle>
            </Grid>
            <Grid item xs={3} sx={{ color: cityColor, fontWeight: 'bold' }}>
              Oraș: {cityLabel}
            </Grid>

            <Grid item xs={1}>
              <LogoutIcon
                onClick={onLogoutClick}
                fontSize='medium'
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                  marginLeft: '18px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const renderFilteringBox = () => (
    <Fragment>
      <Box mx={4} my={3}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={formData.route}
                onChange={(_, value) => {
                  onFormDataChange('route', value);
                  onFormDataChange('startStation', null);
                  onFormDataChange('endStation', null);
                }}
                id='controllable-states-demo'
                options={routes}
                getOptionLabel={(route) =>
                  `${route.routeShortName}: ${route.routeName}`
                }
                renderInput={(params) => (
                  <TextField {...params} label='Traseu' />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <RadioGroup
                aria-label='tour-return-label'
                defaultValue={formData.rsWay}
                name='radio-buttons-group'
                value={formData.rsWay}
                onChange={(event) => {
                  onFormDataChange('rsWay', event.target.value);
                  onFormDataChange('startStation', null);
                  onFormDataChange('endStation', null);
                }}
              >
                <FormControlLabel
                  value='tour'
                  control={<Radio size='small' />}
                  label={
                    <Fragment>
                      <b>Tur:</b>{' '}
                      {`${formData.route?.routeWaypoints[0].name || ''} - ${
                        formData.route?.routeWaypoints[
                          formData.route.routeWaypoints.length - 1
                        ].name || ''
                      }`}
                    </Fragment>
                  }
                />
                {(formData.route?.routeRoundWaypoints.length ||
                  !formData.route) && (
                  <FormControlLabel
                    value='return'
                    control={<Radio size='small' />}
                    label={
                      <Fragment>
                        <b>Retur:</b>{' '}
                        {`${
                          formData.route?.routeRoundWaypoints[0].name || ''
                        } - ${
                          formData.route?.routeRoundWaypoints[
                            formData.route.routeRoundWaypoints.length - 1
                          ].name || ''
                        }`}
                      </Fragment>
                    }
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Autocomplete
                disabled={!formData.route}
                value={formData.startStation}
                onChange={(_, value) => onFormDataChange('startStation', value)}
                id='controllable-states-demo'
                options={
                  formData.route
                    ? formData.route[
                        formData.rsWay === 'tour'
                          ? 'routeWaypoints'
                          : 'routeRoundWaypoints'
                      ]
                    : []
                }
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) =>
                  formData.endStation
                    ? parseInt(option.total, 10) >=
                      parseInt(formData.endStation.total, 10)
                    : false
                }
                renderInput={(params) => (
                  <TextField {...params} label='Stație început' />
                )}
              />
            </FormControl>
          </Grid>
          <ArrowRightAltIcon sx={{ marginTop: 2, marginLeft: 1 }} />
          <Grid item xs={2}>
            <FormControl fullWidth>
              <Autocomplete
                disabled={!formData.route}
                value={formData.endStation}
                onChange={(_, value) => onFormDataChange('endStation', value)}
                id='controllable-states-demo'
                options={
                  formData.route
                    ? formData.route[
                        formData.rsWay === 'tour'
                          ? 'routeWaypoints'
                          : 'routeRoundWaypoints'
                      ]
                    : []
                }
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                getOptionLabel={(option) => option.name}
                getOptionDisabled={(option) =>
                  formData.startStation
                    ? parseInt(option.total, 10) <=
                      parseInt(formData.startStation.total, 10)
                    : false
                }
                renderInput={(params) => (
                  <TextField {...params} label='Stație sfârșit' />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box mx={4} my={4}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disableFuture
                  label='Data început'
                  value={formData.startDate}
                  onChange={(value) => onFormDataChange('startDate', value)}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat={DATE_FORMAT}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                  disableFuture
                  label='Data sfârșit'
                  value={formData.endDate}
                  onChange={(value) => onFormDataChange('endDate', value)}
                  minDate={moment(formData.startDate).startOf('day')}
                  inputFormat={DATE_FORMAT}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={2} container alignItems='flex-start'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.groupStats}
                    onChange={() => {
                      onFormDataChange('groupStats', !formData.groupStats);
                      onFormDataChange('byHourInterval', false);
                      onFormDataChange(
                        'startHour',
                        initialFormDataState.startHour
                      );
                      onFormDataChange('endHour', initialFormDataState.endHour);
                    }}
                  />
                }
                label='Grupează stat.'
              />
            </FormGroup>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth disabled={!formData.groupStats}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.byDay}
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (!checked) {
                          onFormDataChange('periodDays', []);
                          onFormDataChange('filterPeriodDay', false);
                          onFormDataChange('filterGroupedPeriodDay', false);
                        }
                        onFormDataChange('byDay', checked);
                      }}
                    />
                  }
                  label='zi'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.byHourInterval}
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (!checked) {
                          onFormDataChange('hourlyDiscrete', false);
                        }
                        onFormDataChange('byHourInterval', checked);
                      }}
                    />
                  }
                  label='interval orar'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.hourlyDiscrete}
                      disabled={!formData.byHourInterval}
                      onChange={() =>
                        onFormDataChange(
                          'hourlyDiscrete',
                          !formData.hourlyDiscrete
                        )
                      }
                    />
                  }
                  label='interval orar / ora'
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.filterPeriodDay}
                      disabled={!formData.byDay || !formData.groupStats}
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (!checked) {
                          onFormDataChange('periodDays', []);
                          onFormDataChange('filterGroupedPeriodDay', false);
                        }
                        onFormDataChange('filterPeriodDay', checked);
                      }}
                    />
                  }
                  label='zile din săpt.'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.filterGroupedPeriodDay}
                      onChange={(event) =>
                        onFormDataChange(
                          'filterGroupedPeriodDay',
                          event.target.checked
                        )
                      }
                    />
                  }
                  disabled={!formData.filterPeriodDay || !formData.groupStats}
                  label='grupează zile din săpt.'
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth disabled={!formData.filterPeriodDay}>
              <InputLabel id='demo-simple-select-label'>Zile săpt.</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                multiple
                value={formData.periodDays}
                onChange={(event) =>
                  onFormDataChange('periodDays', event.target.value)
                }
              >
                {Object.keys(WEEK_DAYS).map((wdKey) => (
                  <MenuItem
                    key={WEEK_DAYS[wdKey].id}
                    value={WEEK_DAYS[wdKey].id}
                  >
                    {WEEK_DAYS[wdKey].label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {formData.byHourInterval && (
        <Box mx={4} my={4}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    label='Oră început'
                    value={formData.startHour}
                    onChange={(value) => onFormDataChange('startHour', value)}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat={TIME_FORMAT}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    label='Oră sfârșit'
                    value={formData.endHour}
                    onChange={(value) => onFormDataChange('endHour', value)}
                    minTime={formData.startHour}
                    inputFormat={TIME_FORMAT}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box mx={4} my={4}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.removeOutliers}
                      onChange={(event) =>
                        onFormDataChange('removeOutliers', event.target.checked)
                      }
                    />
                  }
                  label='Elimină anomalii'
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='contained'
              size='large'
              type='button'
              disabled={disableSubmitButtton()}
              onClick={onGenerateReportClick}
            >
              Generează
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );

  const renderClosedFilteringBox = () => (
    <Box textAlign='left' pl={3} pt={1}>
      <Typography
        variant='subtitle1'
        sx={{
          fontWeight: 'bold',
          color: theme.palette.primary.main,
        }}
      >
        Traseu: {formData.route?.routeShortName}: {formData.route?.routeName};
        Între stațiile: {formData.startStation?.name} -{' '}
        {formData.endStation?.name}; Între datele:{' '}
        {moment(formData?.startDate).format(DATE_FORMAT)} -{' '}
        {moment(formData?.endDate).format(DATE_FORMAT)};{' '}
        {formData.byHourInterval &&
          `Intre orele: 
          ${moment(formData?.startHour).format(TIME_FORMAT)} -
            ${moment(formData?.endHour).format(TIME_FORMAT)};`}
      </Typography>
    </Box>
  );

  const onCloseHeaderButtonClick = (event) => {
    if (closedHeader) {
      event.stopPropagation();
    }
    setClosedHeader((state) => !state);
  };

  const renderCloseHeaderButton = () => (
    <Box
      onClick={onCloseHeaderButtonClick}
      sx={{
        cursor: 'pointer',
      }}
    >
      {closedHeader ? (
        <ArrowCircleDownIcon
          fontSize='inherit'
          sx={{
            position: 'absolute',
            top: '3px',
            right: '20px',
            color: theme.palette.primary.main,
            fontSize: '36px',
          }}
        />
      ) : (
        <ArrowCircleUpIcon
          fontSize='inherit'
          sx={{
            position: 'absolute',
            top: '-4px',
            right: '20px',
            color: theme.palette.primary.main,
            fontSize: '36px',
          }}
        />
      )}
    </Box>
  );

  return (
    <Fragment>
      {renderLogoTitleRow()}
      <Box
        sx={{
          height: !closedHeader
            ? formData.byHourInterval
              ? '415px'
              : '325px'
            : '40px',
          transition: !closedHeader ? '0.2s' : '0.5s',
          position: 'sticky',
          top: '0',
          left: '0',
          zIndex: '100',
          backgroundColor: '#FFFFFF',
          cursor: closedHeader ? 'pointer' : 'auto',
        }}
        onClick={(event) =>
          closedHeader ? setClosedHeader((state) => !state) : null
        }
      >
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            position: 'relative',
            height: '100%',
            backgroundColor: '#FFFFFF',
          }}
          pb={!closedHeader ? 2 : 0}
        >
          {!closedHeader ? renderFilteringBox() : renderClosedFilteringBox()}
          {renderCloseHeaderButton()}
        </Box>
      </Box>
    </Fragment>
  );
};

export default Header;

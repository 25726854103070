import { createTheme } from '@mui/material/styles';

export const LOCAL_STORAGE_AUTH_TOKEN = 'fleet-route-stats.auth_token';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const PERIOD_TYPES = {
  BY_DAY: 'by_day',
  BY_HOUR_INTERVAL: 'by_hour_interval',
};

export const WEEK_DAYS = {
  MONDAY: { label: 'Luni', id: 0 },
  TUESDAY: { label: 'Marti', id: 1 },
  WEDNESDAY: { label: 'Miercuri', id: 2 },
  THURSDAY: { label: 'Joi', id: 3 },
  FRIDAY: { label: 'Vineri', id: 4 },
  SATURDAY: { label: 'Sambata', id: 5 },
  SUNDAY: { label: 'Duminica', id: 6 },
};
export const WEEK_DAYS_ABBREV = {
  1: 'L',
  2: 'Ma',
  3: 'Mi',
  4: 'J',
  5: 'V',
  6: 'S',
  7: 'D',
};

export const DISTANCE_COLOR = 'red';
export const MIN_COLOR = '#8884d8';
export const AVG_TIME_COLOR = '#ff7300';
export const AVG_SPEED_COLOR = '#2c75ff';
export const MAX_COLOR = '#82ca9d';
export const STDDEV_COLOR = '#ffc658';

export const BAR_CHART_WIDTH = window.innerWidth - 48;
export const BAR_CHART_HEIGHT = 300;

const IASI_DATABASE = 'ctpiasi';
const CLUJ_DATABASE = 'ctpcluj';

const IASI_COLOR = '#00A450';
const CLUJ_COLOR = '#A53692';

const IASI_THEME = createTheme({
  palette: {
    primary: {
      main: IASI_COLOR,
    },
  },
});
const CLUJ_THEME = createTheme({
  palette: {
    primary: {
      main: CLUJ_COLOR,
    },
  },
});

export const CITIES_CONFIG = {
  IASI: {
    database: IASI_DATABASE,
    cityLabel: 'Iași',
    clientID: 1,
    theme: IASI_THEME,
    cityColor: IASI_COLOR,
    logoImage: 'https://tranzy.wink.ro/uploads/info_header.png',
  },
  CLUJ: {
    database: CLUJ_DATABASE,
    cityLabel: 'Cluj',
    clientID: 2,
    theme: CLUJ_THEME,
    cityColor: CLUJ_COLOR,
    logoImage: 'https://tranzy.wink.ro/uploads/info_heade_cjr.png',
  },
};

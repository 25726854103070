import * as React from 'react';
import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useSwitch } from '@mui/core/SwitchUnstyled';
import { Typography } from '@mui/material';

const SwitchRoot = styled('span')`
  display: inline-block;
  position: relative;
  width: 62px;
  height: 34px;
`;

const SwitchInput = styled('input')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled('span')(
  ({ theme }) => `
  position: absolute;
  display: block;
  background-color: ${theme.palette.primary.main};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: 1px;
  left: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &.checked {
    transform: translateX(22px);
  }
`
);

const SwitchTrack = styled('span')`
  background-color: #aab4be;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: block;
`;

const Switch = (props) => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const classNames = `${checked ? 'checked' : ''} ${
    disabled ? 'disabled' : ''
  } ${focusVisible ? 'focusVisible' : ''}`;
  return (
    <Fragment>
      <Typography
        sx={{ color: 'red', marginRight: '10px', fontWeight: 'bold' }}
      >
        Iași
      </Typography>

      <SwitchRoot className={classNames}>
        <SwitchTrack>
          <SwitchThumb className={classNames} />
        </SwitchTrack>

        <SwitchInput {...getInputProps()} aria-label='Demo switch' />
      </SwitchRoot>
      <Typography
        sx={{ color: 'blue', marginLeft: '10px', fontWeight: 'bold' }}
      >
        Cluj
      </Typography>
    </Fragment>
  );
};

export default Switch;

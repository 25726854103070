import React from 'react';
import { Box, Typography } from '@mui/material';
import { DISTANCE_COLOR } from '../utils/constants';

const CustomTooltip = ({ showDistance = false, active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <Box
        p={1}
        sx={{
          backgroundColor: '#FFF',
          textAlign: 'left',
          border: '1px solid #000',
        }}
      >
        <Typography align='center' variant='h6'>
          {label}
        </Typography>
        {showDistance && (
          <Typography key={DISTANCE_COLOR} sx={{ color: DISTANCE_COLOR }}>
            distance: {payload[0].payload.distance_label}
          </Typography>
        )}
        {payload.map((vh) => (
          <Typography key={vh.color} sx={{ color: vh.color }}>
            {vh.dataKey}: {vh.payload[`${vh.dataKey}_label`]}
          </Typography>
        ))}
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;

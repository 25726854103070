import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import GlobalContext from '../GlobalContext';

const LoadingScreenContainer = styled('div')({
  content: '""',
  display: 'block',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(20, 20, 20, 0.6)',
  zIndex: '999',
});

const Loader = styled('div')({
  top: '50%',
  left: '50%',
  position: 'fixed',
  transform: 'translate(-50%, -50%)',
});

const LoadingScreen = () => {
  const {
    globalState: { loadingApp },
  } = useContext(GlobalContext);

  if (!loadingApp) return null;

  return (
    <LoadingScreenContainer>
      <Loader>
        <CircularProgress size={100} color='primary' />
      </Loader>
    </LoadingScreenContainer>
  );
};

export default LoadingScreen;

import moment from 'moment';
import { axios } from '../components/AxiosInterceptor';
import { toastError } from '../utils/utils';
import {
  TIME_FORMAT,
  PERIOD_TYPES,
  LOCAL_STORAGE_AUTH_TOKEN,
  CITIES_CONFIG,
} from '../utils/constants';
import { getHumanReadableTime } from '../utils/utils';

const {
  REACT_APP_FLEETMAN_API_URL: FLEETMAN_API_URL,
  REACT_APP_API_URL: API_URL,
} = process.env;

export const authenticateUser = async (
  { email, password },
  setAppState,
  navigate
) => {
  try {
    let {
      data: { valid, token, userInfo },
    } = await axios.post(
      `${FLEETMAN_API_URL}/login`,
      { email, password },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (valid === true && token?.length) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, token);

      const cityKey = Object.keys(CITIES_CONFIG).find(
        (cityKey) => CITIES_CONFIG[cityKey].clientID === userInfo.clientID
      );

      setAppState((state) => ({
        ...state,
        cityConfig: CITIES_CONFIG[cityKey],
        userData: {
          isAuthenticated: true,
          email: userInfo.userEmail,
        },
      }));
      navigate('/');
    } else {
      // valid === false
      toastError('Login failed. Invalid credentials!');
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
  }
};

export const fetchUserInfo = async (setAppState) => {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
    if (!token?.length) {
      setAppState((state) => ({
        ...state,
        userData: {
          isAuthenticated: false,
          email: '',
        },
      }));
      return false;
    }
    let {
      data: { valid, userInfo },
    } = await axios.get(`${FLEETMAN_API_URL}/user`, {
      headers: {
        Authorization: token,
      },
    });

    if (valid === true) {
      const cityKey = Object.keys(CITIES_CONFIG).find(
        (cityKey) => CITIES_CONFIG[cityKey].clientID === userInfo.clientID
      );

      setAppState((state) => ({
        ...state,
        cityConfig: CITIES_CONFIG[cityKey],
        userData: {
          isAuthenticated: true,
          email: userInfo.userEmail,
        },
      }));
    } else {
      setAppState((state) => ({
        ...state,
        userData: {
          isAuthenticated: false,
          email: '',
        },
      }));
    }

    return valid;
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    return false;
  }
};

// eliminate stations that do not have a name or have consecutive stations with same name.
const filterRouteWaypoints = (routes) => {
  routes.forEach((route) => {
    route.routeWaypoints = route.routeWaypoints.filter((r) => r.name);
    route.routeRoundWaypoints = route.routeRoundWaypoints.filter((r) => r.name);

    const rwpLength = route.routeWaypoints.length;
    if (
      rwpLength &&
      route.routeWaypoints[rwpLength - 1].name ===
        route.routeWaypoints[rwpLength - 2].name
    ) {
      route.routeWaypoints.splice(rwpLength - 2, 1);
    }

    const rrwpLength = route.routeRoundWaypoints.length;
    if (
      rrwpLength &&
      route.routeRoundWaypoints[rrwpLength - 1].name ===
        route.routeRoundWaypoints[rrwpLength - 2].name
    ) {
      route.routeRoundWaypoints.splice(rrwpLength - 2, 1);
    }
  });
  return routes;
};

export const fetchRoutes = async () => {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
  try {
    let response = await axios.get(`${FLEETMAN_API_URL}/routes`, {
      headers: {
        Authorization: token,
      },
    });

    if (response.data.valid) {
      return filterRouteWaypoints(response.data.data);
    }

    throw new Error("Couldn't fetch routes");
  } catch (err) {
    console.error(err);
    return err;
  }
};

const getSubmitPayload = (database, formData) => {
  const {
    route: { routeID },
    startStation,
    endStation,
    rsWay,
    startDate,
    endDate,
    startHour,
    endHour,
    groupStats,
    byDay,
    byHourInterval,
    hourlyDiscrete,
    filterPeriodDay,
    periodDays,
    filterGroupedPeriodDay,
    removeOutliers,
  } = formData;

  const payload = {
    database,
    route_id: routeID,
    start_station_id: startStation.stationID,
    end_station_id: endStation.stationID,
    rs_way: rsWay === 'tour' ? 1 : 2,
    start_ts: startDate.unix(),
    // end_ts: moment(endDate).endOf('day').unix(), // 23:59 current day
    end_ts: moment(endDate).add(1, 'days').unix(), // 00:00 next day
    period_types: [],
    remove_outliers: removeOutliers,
  };

  if (groupStats) {
    byDay && payload.period_types.push(PERIOD_TYPES.BY_DAY);
    byHourInterval && payload.period_types.push(PERIOD_TYPES.BY_HOUR_INTERVAL);

    if (byHourInterval) {
      payload.start_hour = startHour.format(TIME_FORMAT);
      payload.end_hour = endHour.format(TIME_FORMAT);
    }

    if (hourlyDiscrete) {
      payload.hourly_discrete = true;
    }
    if (filterPeriodDay) {
      payload.period_days = periodDays;
    }
    if (filterGroupedPeriodDay) {
      payload.day_grouping = true;
    }
  }
  return payload;
};

export const fetchReport = async (database, formData, setAppState) => {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
    const submitPayload = getSubmitPayload(database, formData);
    let response = await axios.get(`${API_URL}/stats/route`, {
      params: submitPayload,
      headers: {
        Authorization: token,
      },
    });
    if (response.data && Object.keys(response.data).length) {
      return setAppState((state) => ({
        ...state,
        headerData: {
          periodTypes: submitPayload.period_types,
          startStationName: formData.startStation.name,
          endStationName: formData.endStation.name,
        },
        reportData: {
          ...response.data,
          stats: dataToHumanReadableFormat(response.data.stats),
          groupedStats: response.data.grouped_stats.map((gs) => ({
            ...gs,
            stats: dataToHumanReadableFormat(gs.stats),
          })),
        },
      }));
    }

    throw new Error("Couldn't fetch report");
  } catch (err) {
    console.error(err);
    return err;
  }
};

// transform times from seconds to human readable format
const dataToHumanReadableFormat = (stats) => {
  Object.keys(stats).forEach((key) => {
    let value = stats[key];
    if (['min_time', 'max_time', 'avg_time', 'stddev'].indexOf(key) !== -1) {
      stats[`${key}_label`] = getHumanReadableTime(value);
    }
    if (key === 'avg_speed') {
      stats[`${key}_label`] = `${value} km/h`;
    }
    if (key === 'distance') {
      stats[`${key}_label`] = `${value} metri`;
    }
    if (['stats_per_vehicle', 'stats_per_stations'].indexOf(key) !== -1) {
      value.forEach((v) => {
        v = {
          ...v,
          stats: dataToHumanReadableFormat(v.stats),
        };
      });
    }
  });
  return stats;
};

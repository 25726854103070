import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchUserInfo } from '../actions';
import GlobalContext from '../GlobalContext';

const AuthHOC = ({ Component, ...props }) => {
  const {
    globalState: {
      userData: { isAuthenticated },
    },
    setGlobalState,
  } = useContext(GlobalContext);

  useEffect(() => {
    if (isAuthenticated === null) {
      fetchUserInfo(setGlobalState);
    }
  }, [isAuthenticated, setGlobalState]);

  if (isAuthenticated === true) {
    return <Component {...props} />;
  }

  if (isAuthenticated === false) {
    return <Navigate to='/login' replace />;
  }

  return null;
};

export default AuthHOC;

import React from 'react';
import { CITIES_CONFIG } from './utils/constants';

export const globalState = {
  cityConfig: CITIES_CONFIG.IASI, // default value
  userData: {
    isAuthenticated: null,
    email: '',
  },
  headerData: {},
  reportData: { stats: {}, groupedStats: [] },
  loadingApp: false,
};

const defaultContextValue = {
  globalState,
  setGlobalState: () => {},
};

export default React.createContext(defaultContextValue);

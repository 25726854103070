import { useEffect, useContext } from 'react';
import GlobalContext from '../GlobalContext';
import * as _axios from 'axios';
import { getToastErrorMessage, toastError } from '../utils/utils';
import { LOCAL_STORAGE_AUTH_TOKEN } from '../utils/constants';

const AxiosInterceptor = () => {
  const { setGlobalState } = useContext(GlobalContext);

  useEffect(() => {
    const setLoadingScreen = (loadingApp) =>
      setGlobalState((state) => ({ ...state, loadingApp }));

    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setLoadingScreen(true);
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLoadingScreen(false);
        return response;
      },
      (error) => {
        setLoadingScreen(false);
        toastError(getToastErrorMessage(error));

        if ([401, 403].indexOf(error.response?.status) !== -1) {
          localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
          setGlobalState((state) => ({
            ...state,
            userData: {
              isAuthenticated: false,
              email: '',
            },
          }));
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [setGlobalState]);

  return null;
};

export const axios = _axios.create();
export default AxiosInterceptor;

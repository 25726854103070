import React, { useState } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Home, Login } from './containers';
import { LoadingScreen, AxiosInterceptor, AuthHOC } from './components';
import GlobalContext, {
  globalState as initialGlobalState,
} from './GlobalContext';

const App = () => {
  const [globalState, setGlobalState] = useState(initialGlobalState);

  return (
    <div className='App'>
      <GlobalContext.Provider value={{ globalState, setGlobalState }}>
        <ThemeProvider theme={globalState.cityConfig.theme}>
          <AxiosInterceptor />
          <LoadingScreen />
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<AuthHOC Component={Home} />} />
              <Route path='/login' element={<Login />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </GlobalContext.Provider>
    </div>
  );
};

export default App;

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Box, Grid, Typography, Tabs, Tab, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Brush,
  LineChart,
  Line,
} from 'recharts';
import moment from 'moment';

import GlobalContext from '../GlobalContext';
import { CustomTooltip } from './';

import {
  AVG_TIME_COLOR,
  AVG_SPEED_COLOR,
  BAR_CHART_HEIGHT,
  BAR_CHART_WIDTH,
  MAX_COLOR,
  MIN_COLOR,
  STDDEV_COLOR,
  DATE_FORMAT,
  WEEK_DAYS_ABBREV,
  PERIOD_TYPES,
  WEEK_DAYS,
  DISTANCE_COLOR,
} from '../utils/constants';
import { getCSVFileContent } from '../utils/utils';

const CustomTab = styled(Tab)({
  textTransform: 'initial',
});

const initialHiderBarsState = {
  hideVehicleStats: {
    min_time: false,
    avg_time: false,
    max_time: false,
  },
  hideStationsStats: {
    min_time: false,
    avg_time: false,
    max_time: false,
  },
};

const initialReportDataState = {
  vehicleData: [],
  stationsData: [],
};

const parseReportDataStats = (stats) => {
  const vehicleData = stats?.stats_per_vehicle?.map((vh) => ({
    ...vh.stats,
    vehicle_name: `Nr. parc: ${vh.vehicle_name}`,
  }));

  const stationsData = stats.stats_per_stations?.map((st) => ({
    ...st.stats,
    between_stations: `${st.first_station_name} - ${st.second_station_name}`,
  }));

  return { ...stats, vehicleData, stationsData };
};

const ReportData = () => {
  const {
    globalState: {
      cityConfig: { theme, cityColor },
      headerData: { periodTypes, startStationName = '', endStationName = '' },
      reportData,
      reportData: { stats, start_ts, end_ts, groupedStats },
    },
  } = useContext(GlobalContext);

  const [selectedStats, setSelectedStats] = useState(initialReportDataState);
  const [hideBars, setHideBars] = useState(initialHiderBarsState);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const StationNameSpan = styled('span')`
    color: ${theme.palette.primary.main};
    font-weight: bold;
  `;

  useEffect(() => {
    if (Object.keys(stats).length) {
      const parsedStats = parseReportDataStats(stats);
      setSelectedStats(parsedStats);
    }
    setSelectedTabIndex(0);
    setHideBars(initialHiderBarsState);
  }, [stats]);

  useEffect(() => {
    let _stats;
    if (selectedTabIndex === 0) {
      _stats = stats;
    } else {
      _stats = groupedStats[selectedTabIndex - 1]?.stats || {};
    }

    if (Object.keys(_stats).length) {
      const parsedStats = parseReportDataStats(_stats);
      setSelectedStats(parsedStats);
    }
  }, [selectedTabIndex, groupedStats, stats]);

  const getSelectingPeriodLabel = (gs) => {
    if (
      gs.day_grouping === true &&
      periodTypes.indexOf(PERIOD_TYPES.BY_HOUR_INTERVAL) !== -1
    ) {
      const dayName =
        WEEK_DAYS[moment(gs.start_ts).format('dddd').toUpperCase()].label;
      const startHour = moment(gs.start_ts).format('HH:mm');
      const endHour = moment(gs.end_ts).format('HH:mm');
      return `${dayName}, ${startHour} - ${endHour}`; // Marti, 05:00 - 09:00
    }

    if (gs.day_grouping === true) {
      return WEEK_DAYS[moment(gs.start_ts).format('dddd').toUpperCase()].label; // Marti
    }

    const weekDayLabel = `${moment(gs.start_ts).format(DATE_FORMAT)} (${
      WEEK_DAYS_ABBREV[moment(gs.start_ts).isoWeekday()]
    })`;

    if (periodTypes.indexOf(PERIOD_TYPES.BY_HOUR_INTERVAL) !== -1) {
      const startHour = moment(gs.start_ts).format('HH:mm');
      const endHour = moment(gs.end_ts).format('HH:mm');
      return `${weekDayLabel}, ${startHour} - ${endHour}`; // 18/04/2022 (L), 05:00 - 09:00
    }

    if (periodTypes.indexOf(PERIOD_TYPES.BY_DAY) !== -1) {
      return weekDayLabel; // 18/04/2022 (L)
    }

    return '';
  };

  const onDownloadCSVFileClick = () => {
    const fileRows = getCSVFileContent(stats, start_ts, end_ts, groupedStats);

    const csvContent = `data:text/csv;charset=utf-8,${fileRows
      .map((e) => e.join(','))
      .join('\n')}`;
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'raport.csv'); // to be changed
    document.body.appendChild(link);

    link.click();
  };

  const onDownloadJSONFileClick = () => {
    const jsonContent = `data:text/json;charset=utf-8,${JSON.stringify(
      reportData
    )}`;
    const encodedUri = encodeURI(jsonContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'raport.json'); // to be changed
    document.body.appendChild(link);

    link.click();
  };

  const renderSelectingReportDataPeriod = () => (
    <Box mb={5} sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTabIndex <= groupedStats.length ? selectedTabIndex : 0}
          onChange={(_, index) => setSelectedTabIndex(index)}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <CustomTab label='Statistici generale' />
          {groupedStats?.map((gs) => (
            <CustomTab key={gs.start_ts} label={getSelectingPeriodLabel(gs)} />
          ))}
        </Tabs>
      </Box>
    </Box>
  );

  const renderTimeBetweenStationNames = () => (
    <Fragment>
      Statistici între stațiile{' '}
      {startStationName && endStationName ? (
        <Fragment>
          <StationNameSpan>{startStationName}</StationNameSpan> și{' '}
          <StationNameSpan>{endStationName}</StationNameSpan>
        </Fragment>
      ) : (
        'selectate'
      )}
    </Fragment>
  );

  const renderGeneralStats = () => (
    <Fragment>
      <Box textAlign='left' mb={2}>
        <Typography variant='h5'>{renderTimeBetweenStationNames()}</Typography>
      </Box>
      <Grid container spacing='3' rowSpacing='20'>
        <Grid item container justifyContent='space-between'>
          <Typography variant='h6' sx={{ color: DISTANCE_COLOR }}>
            Distanță: {selectedStats.distance_label}
          </Typography>
          <Typography variant='h6' sx={{ color: MIN_COLOR }}>
            Timp minim: {selectedStats.min_time_label}
          </Typography>
          <Typography variant='h6' sx={{ color: AVG_TIME_COLOR }}>
            Timp mediu: {selectedStats.avg_time_label}
          </Typography>
          <Typography variant='h6' sx={{ color: AVG_SPEED_COLOR }}>
            Viteza medie: {selectedStats.avg_speed_label}
          </Typography>
          <Typography variant='h6' sx={{ color: MAX_COLOR }}>
            Timp maxim: {selectedStats.max_time_label}
          </Typography>
        </Grid>

        <Grid item container justifyContent='space-between'>
          <Typography variant='h6' sx={{ color: STDDEV_COLOR }}>
            Deviația standard: {selectedStats.stddev_label}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );

  const renderDownloadRaportButtons = () => {
    return (
      <Grid container spacing={2} justifyContent='end' alignItems='center'>
        <Grid item xs={2}>
          <Button
            variant='contained'
            size='large'
            type='button'
            disabled={
              !selectedStats.stationsData.length &&
              !selectedStats.vehicleData.length
            }
            onClick={onDownloadCSVFileClick}
          >
            Descarcă (CSV)
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant='contained'
            size='large'
            type='button'
            disabled={
              !selectedStats.stationsData.length &&
              !selectedStats.vehicleData.length
            }
            onClick={onDownloadJSONFileClick}
          >
            Descarcă (JSON)
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderStationsPairTimeStats = () => {
    const { stationsData } = selectedStats;
    const { hideStationsStats } = hideBars;

    return (
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>Statistici între perechi de stații</Typography>
        <BarChart
          width={BAR_CHART_WIDTH}
          height={BAR_CHART_HEIGHT}
          data={stationsData}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='between_stations' xAxisId={0} />
          <XAxis xAxisId={1} hide />
          <XAxis xAxisId={2} hide />

          <YAxis />
          <Tooltip content={<CustomTooltip showDistance />} />
          <Legend
            verticalAlign='top'
            wrapperStyle={{ lineHeight: '40px' }}
            onClick={(data) =>
              setHideBars((state) => ({
                ...state,
                hideStationsStats: {
                  ...state.hideStationsStats,
                  [data.dataKey]: !state.hideStationsStats[data.dataKey],
                },
              }))
            }
          />
          <Brush dataKey='between_stations' height={30} stroke={cityColor} />

          <Bar
            dataKey='max_time'
            fill={MAX_COLOR}
            xAxisId={2}
            hide={hideStationsStats.max_time}
            barSize={200}
          />
          <Bar
            dataKey='avg_time'
            fill={AVG_TIME_COLOR}
            xAxisId={1}
            hide={hideStationsStats.avg_time}
            barSize={175}
          />
          <Bar
            dataKey='min_time'
            fill={MIN_COLOR}
            xAxisId={0}
            hide={hideStationsStats.min_time}
            barSize={150}
          />
        </BarChart>
      </Grid>
    );
  };

  const renderStationsPairSpeedStats = () => {
    const { stationsData } = selectedStats;

    return (
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>
          Viteza medie între perechi de stații
        </Typography>

        <LineChart
          width={BAR_CHART_WIDTH}
          height={BAR_CHART_HEIGHT}
          data={stationsData}
          margin={{ top: 40 }}
        >
          <Line type='monotone' dataKey='avg_speed' stroke={AVG_SPEED_COLOR} />
          <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
          <XAxis dataKey='between_stations' />
          <YAxis />
          <Tooltip />
        </LineChart>
      </Grid>
    );
  };

  const renderVehiclesTimeStats = () => {
    const { vehicleData } = selectedStats;
    const { hideVehicleStats } = hideBars;

    return (
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>Timpi vehicule</Typography>
        <BarChart
          width={BAR_CHART_WIDTH}
          height={BAR_CHART_HEIGHT + 50}
          data={vehicleData}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='vehicle_name' xAxisId={0} />
          <XAxis xAxisId={1} hide />
          <XAxis xAxisId={2} hide />

          <YAxis />
          <Tooltip content={<CustomTooltip />} />

          <Legend
            verticalAlign='top'
            wrapperStyle={{ lineHeight: '40px' }}
            onClick={(data) =>
              setHideBars((state) => ({
                ...state,
                hideVehicleStats: {
                  ...state.hideVehicleStats,
                  [data.dataKey]: !state.hideVehicleStats[data.dataKey],
                },
              }))
            }
          />
          <Brush dataKey='vehicle_name' height={30} stroke={cityColor} />

          <Bar
            dataKey='max_time'
            value='max_time_label'
            fill={MAX_COLOR}
            xAxisId={2}
            hide={hideVehicleStats.max_time}
            barSize={200}
          />
          <Bar
            dataKey='avg_time'
            value='avg_time_label'
            fill={AVG_TIME_COLOR}
            xAxisId={1}
            hide={hideVehicleStats.avg_time}
            barSize={175}
          />
          <Bar
            dataKey='min_time'
            value='min_time_label'
            fill={MIN_COLOR}
            xAxisId={0}
            hide={hideVehicleStats.min_time}
            barSize={150}
          />
        </BarChart>
      </Grid>
    );
  };

  const renderVehiclesSpeedStats = () => {
    const { vehicleData } = selectedStats;

    return (
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>Viteza medie vehicule</Typography>
        <BarChart
          width={BAR_CHART_WIDTH}
          height={BAR_CHART_HEIGHT}
          margin={{ top: 40 }}
          data={vehicleData}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='vehicle_name' xAxisId={0} />

          <YAxis />
          <Tooltip content={<CustomTooltip />} />

          <Brush dataKey='avg_speed' height={30} stroke={cityColor} />

          <Bar
            dataKey='avg_speed'
            value='avg_speed'
            fill={AVG_SPEED_COLOR}
            xAxisId={0}
            barSize={200}
          />
        </BarChart>
      </Grid>
    );
  };

  return (
    <Box p={3} mt={1} mb={3}>
      <Box mt={1} mb={3}>
        {renderDownloadRaportButtons()}
      </Box>
      {renderSelectingReportDataPeriod()}
      {renderGeneralStats()}

      <Box mt={5}>{renderStationsPairTimeStats()}</Box>
      <Box mt={5}>{renderStationsPairSpeedStats()}</Box>

      <Box mt={5}>{renderVehiclesTimeStats()}</Box>
      <Box mt={5}>{renderVehiclesSpeedStats()}</Box>
    </Box>
  );
};

export default ReportData;
